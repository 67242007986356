import React from 'react';
import {
  FormDataConsumer,
  SaveButton, SelectInput, SimpleForm, TextInput, Toolbar, useNotify, useTranslate,
} from 'react-admin';
import { v4 as uuidv4 } from 'uuid';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});

const CustomToolbar = props => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);
const luhnCheck = number => number
  .split('')
  .reverse()
  .map((digit, idx) => {
    let num = parseInt(digit, 10);
    if (idx % 2 === 1) {
      num *= 2;
      if (num > 9) num -= 9;
    }
    return num;
  })
  .reduce((acc, curr) => acc + curr, 0) % 10 === 0;
const validateCardCreation = (values) => {
  const errors = {};
  if (!values.code) {
    errors.code = 'ra.validation.required';
  }
  if (!values.type) {
    errors.type = 'ra.validation.required';
  }
  if (values.code && !luhnCheck(values.code)) {
    errors.code = 'user.cards.luhn';
  }
  return errors;
};
const AddCard = ({ record, setCard }) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const PREFIX_PASS = '913572';
  const { id } = record;
  const typeOptions = [
    { id: 'Carrefour', name: 'Carrefour' },
    { id: 'PASS', name: 'PASS' },
  ];
  const save = (values) => {
    const { type, code } = values;
    const newCard = {
      id: uuidv4(),
      code,
      type,
      createdAt: '2024-06-18T15:21:14Z',
    };
    notify(`${translate('user.cards.success.add')}${id}`);
    setCard(newCard);
  };

  return (
    <SimpleForm save={save} toolbar={<CustomToolbar />} validate={validateCardCreation}>
      <Typography variant="h2" component="h2">
        {translate('user.cards.addCardTitle')}
      </Typography>
      <SelectInput source="type" label={translate('user.cards.type')} formClassName={classes.inlineBlock} choices={typeOptions} />
      <FormDataConsumer>
        {({ formData }) => {
          if (!formData.type) return null;
          if (formData.type === 'PASS') {
            return (
              <TextInput
                fullWidth
                source="code"
                formClassName={classes.inlineBlock}
                label={translate('user.cards.code')}
                InputProps={{
                  className: classes.inlineBlock,
                  startAdornment: <InputAdornment position="start">{PREFIX_PASS}</InputAdornment>,
                }}
              />
            );
          }
          return (<TextInput fullWidth source="code" label={translate('user.cards.code')} formClassName={classes.inlineBlock} />);
        }
      }

      </FormDataConsumer>
    </SimpleForm>
  );
};

export default AddCard;
