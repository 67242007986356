import React from 'react';
import {
  Button, DateInput,
  SelectInput, SimpleForm, TextInput, Toolbar, useNotify, useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import IconSave from '@material-ui/icons/Delete';

const useStyles = makeStyles({
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
});


const ShowCard = ({ card, setCard, record }) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const { id } = record;
  const typeOptions = [
    { id: 'Carrefour', name: 'Carrefour' },
    { id: 'PASS', name: 'PASS' },
  ];
  const save = () => {
    notify(`${translate('user.cards.success.delete')}${id}`);
    setCard(null);
  };
  const CustomToolbar = props => (
    <Toolbar {...props}>
      <Button
        variant="contained"
        color="red"
        label="Delete Card"
        style={{ maxWidth: '140px', backgroundColor: 'red' }}
        onClick={save}
        startIcon={<IconSave />}
      />
    </Toolbar>
  );

  return (
    <SimpleForm record={card} save={save} toolbar={<CustomToolbar />}>
      <Typography variant="h2" component="h2">
        {translate('user.cards.deleteCardTitle')}
      </Typography>
      <SelectInput
        options={{ disabled: true }}
        source="type"
        label={translate('user.cards.type')}
        formClassName={classes.inlineBlock}
        choices={typeOptions}
      />
      <TextInput
        options={{ disabled: true }}
        source="code"
        label={translate('user.cards.code')}
        formClassName={classes.inlineBlock}
      />
      <DateInput
        formClassName={classes.inlineBlock}
        source="createdAt"
        options={{ disabled: true }}
        label={translate('user.edit.createdAt')}
      />
    </SimpleForm>
  );
};

export default ShowCard;
