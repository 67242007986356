import React from 'react';

import {
  Datagrid, List, TextField, DateField, Filter, TextInput,
} from 'react-admin';


const CardFilter = ({ ...props }) => (
  <Filter {...props} data-testid="card-search">
    <TextInput source="code" alwaysOn label="Card Number" />
  </Filter>
);
const CardList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    filters={<CardFilter {...props} />}
  >
    <Datagrid>
      <TextField source="code" label="Card Number" />
      <TextField source="type" />
      <DateField source="createdAt" />
    </Datagrid>
  </List>
);

export default CardList;
