import React from 'react';

import {
  Datagrid, List, TextField, DateField, Filter, TextInput, required, minLength,
} from 'react-admin';

const minChar = 3;
const validateName = [minLength(minChar)];
const ManufacturerFilter = ({ ...props }) => (
  <Filter {...props} data-testid="manufacturer-search">
    <TextInput source="name" label="Manufacturer Name" alwaysOn validate={validateName} />
  </Filter>
);
const ManufacturerList = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    filters={<ManufacturerFilter {...props} />}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <DateField source="createdAt" label="Creation Date" />
    </Datagrid>
  </List>
);

export default ManufacturerList;
