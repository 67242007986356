import React, { useState } from 'react';

import Box from '@material-ui/core/Box';
import { Loading } from 'react-admin';
import AddCard from './AddCard';
import ShowCard from './ShowCard';


const UserCards = ({ record }) => {
  const [card, setCard] = useState(null);
  if (!record) {
    return <Loading />;
  }
  return (
    <Box sx={{ margin: '20px', boxSizing: 'border-box' }}>
      {!card && <AddCard record={record} setCard={setCard} />}
      {card && <ShowCard card={card} record={record} setCard={setCard} />}
    </Box>
  );
};

export default UserCards;
